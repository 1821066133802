import {
  LoadingModelBase,
  LoadingModelCommon,
  LoadingModelFail,
  LoadingState
} from './loading-state'
import { LoadingStatuses } from './loading-statuses'

export function getDefaultLoadingState<
  T extends object,
  L extends LoadingState<T>
>(operations: T): L {
  return Object.values(operations).reduce(
    (acc: L, operation: keyof L) => ({
      ...acc,
      [operation]: {
        id: operation,
        status: LoadingStatuses.NotStarted
      }
    }),
    {} as L
  )
}

export function updateLoadingState<T, L extends LoadingState<T>>(
  state: L,
  {
    operation,
    message,
    status,
    metadata
  }: {
    operation: keyof L
  } & Pick<LoadingModelBase<keyof L, unknown>, 'metadata'> &
    (LoadingModelCommon | LoadingModelFail)
) {
  return {
    ...state,
    [operation]: {
      ...state[operation],
      status,
      message,
      metadata
    }
  }
}
